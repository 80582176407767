
.table-margin{
    margin-left: 20px;
}

.utilizadores-footer-margin{
    margin-top: 30px;
}

.load-height{
    height: 71%;
}

.btn-table{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    outline: none !important;
    background:transparent;

}

.data-table-view-btn{
    color: #198754 !important;

}

.dash-altura{

    height: 90vh !important;
}