*{
    box-sizing: border-box;
}

.anuncioContainer-editar{
    max-height: 200vh;
    display:flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 100px !important;  
}


.anuncioTitle-editar{
    margin-top: 70px;
    margin-bottom: 30px;
}

.anuncioDadosContainer-editar{
    margin-top: 20px;
}

.anuncioDadosBody-editar{
    margin-top: 20px;
    border-radius: 10px;
}



form{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
}


.anuncioFotoContainer-editar{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.nota-color{

    color: red;
}

.obg{
    font-size: 18px;
    color: red;
}




 /*Anúcio DADOS*/

 .anuncioButton-editar{
    height: 50px;
    width: 25%;
    border: none;
    background-color: #150050 !important;
    color: white !important;
    border-radius: 5px;
    font-weight: 500;
    margin-left: auto;
    margin-right: 75px;
 }

 .anuncioButton-editar:hover{
    background-color: #1f0369 !important;
    color: white !important;
 }
 


 