.listar-candidatos-container{
    height: 100vh;
    margin-top: 130px !important;

}

.btn_listarCdandidatos{
    cursor: pointer;
    height: 20px;
    width: 20px;

}
listarCdandidatos:last-child{
    margin-left: 15px;
}


.marginBlistarCdandidatos{
    margin-bottom: 2px;
}