.items-container{
    height: 50vh;
    margin-left: 30px;
    overflow: hidden;
    overflow-y: scroll;
}

.add-item{
    margin-left: 10px;
}

.item-title{
    margin-bottom: 58px;
}

.item-button{
    border: none;
    outline: none;
    font-size: 11px;
    font-weight: 700;
    border-radius: 5px;
    height: 35px;
    width: 70px;
    color: #fff;
    text-align: center;
    transition: .5s ease all;
    
}

.item-button1{

    background-color: #6777ef;
}

.item-button1:hover{
    background-color: #4d5bc4; 
}

.item-button2{

    background-color: rgb(17, 146, 17);
}

.item-button2:hover{

    background-color: rgb(32, 129, 32);
}


.item-button3{
    background-color: rgb(182, 30, 30);
}

.item-button3:hover{
    background-color: rgb(161, 37, 37);
}