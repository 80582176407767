.registo-title{
    text-decoration:none;
    margin-top: 80px;
    margin-bottom: 40px;
}

.registo-container{
    max-height: 230vh;
    display:flex;
    align-items: center;
    flex-direction: column;
    margin-top: 40px; 
}


.registo-dados{
    margin-top: 20px;
    border-radius: 10px;
}

.registo-dados .cabecalhos{
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 3%;
}

.registo-dados .cabecalhos .title-button{
    color: #150050;
    background-color: transparent;
    border: none ;
    padding: 10px;
    
}


.registo-dados .cabecalhos .title-button:hover{
    background-color: #150050;
    color:white;
}

.activeTitleButton{
    background-color: #150050;
    color:white;
    padding: 10px;
}

.activeTitleButton:hover{

    color:white;

}

.registo-divisao{
    height: 1px;
    width: 95%;
    background-color: rgb(175, 170, 170);
    margin-left: 20px;
}



form{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
}

.registo-foto-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.registo-foto-button{
   
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

}



form .registo-foto-container .registo-foto-button .input-registo-foto #foto{

    display: none;

}


.registo-foto-container .registo-foto-button input, .registo-foto-container .registo-foto-button label{
    cursor: pointer;
    width: 90px;
    height: 37px;
    border: none;
    border-radius: 5px;
}

form .registo-foto-container  .registo-foto-view{

    height:200px;
    width: 200px;
    border-style: dotted;
    border-color: rgb(175, 170, 170);
    border-radius: 50%;
}

form .registo-foto-container .registo-foto-button label{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #150050;
}

form .registo-foto-container .registo-foto-button label:hover{
    
    background-color: #1f0369;
 }

form .registo-foto-container .registo-foto-button input[type="button"]{

    background-color: rgb(182, 26, 26);
    color: white;
    margin-left: 5%;
 }

 form .registo-foto-container .registo-foto-button input[type="button"]:hover{

    background-color: rgb(199, 33, 33);
 }



 /*PERFIL DADOS*/

 .registo-button{
    height: 50px;
    width: 30%;
    border: none;
    background-color: #150050 !important;
    color: white !important;
    border-radius: 5px;
    font-weight: 500;
    margin-left: auto;
    margin-right: 50px;
 }
 .registo-button:hover{
    
    background-color: #1f0369 !important;
    color: #fff !important;
 }

 textarea{
    resize: none;
 }

 label{
    font-weight: 500;
    color: rgb(77, 75, 75);
 }

 .obg{
    font-size: 18px;
    color: red;
}


/*------------LOAD---------------*/

.load-container{
    width:100%;
    height: 100vh;
    background-color: #fefefe;
}

