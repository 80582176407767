.visualizar-anuncio{
    min-height: 160vh;
    background: url(../../../../assets/anuncio-capa.jpg) no-repeat fixed;
    padding-top: 90px;

}


.text{
    text-align: justify;
}

.visualizar-anuncio-title{

    text-decoration:none;
}



.visualizar-anuncio-details{
    padding: 80px 80px 0px 80px;
    background-color: white;
    min-height: 130vh;
}

.visualizar-anuncio-top strong{
    color:rgb(99, 96, 96);
}

.visualizar-anuncio-icon{

    /*color:rgb(136, 131, 131);*/
}
.visualizar-anuncio-details-info1-title{

    text-decoration:none;
    color: rgb(53, 50, 50);
    font-weight: 500;

}

.visualizar-anuncio-details-head-img{
    border-radius:50%;
    height: 70px;
    width: 70px;
    color:rgb(175, 170, 170);
}

/*--------------------------------------------*/


.visualizar-anuncio-details-info2{

    width: 100%;
}


.visualizar-anuncio-details-info2-1, 
.visualizar-anuncio-details-info2-2{

    border: 2px solid rgb(194, 189, 189);
    border-radius: 5px;
}


.visualizar-anuncio-button{

    font-weight: 500;

}


.details-info2-1-title{
    font-weight: 700;
    color: rgb(53, 50, 50); 
}

.details-info2-1-conteudo strong{

    margin-right: 5px;
}

.details-info2-1-conteudo{

    font-size: 1rem;
}

.details-info2-1-conteudo strong{

    color: rgb(94, 91, 91);
}

.documents-title{
    font-weight: 700;
    color: rgb(53, 50, 50);  
}

