.lista-anuncio-container{
    min-height: 70vh;
    box-sizing: border-box;
}

.lista-anuncio-title{
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 50px;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, .8) !important;
    font-size: 2em !important;
}


.lista-anuncio-dados{
    background-color:white;
}


/*Parte 1 Inícipo*/
.lista-anuncio-nome-utilizador{
    font-size: 20px;
    color:#37198c;
    font-weight: bold;
}

.lista-anuncio-nome-utilizador-ico{
    color:#37198c;
    font-size: 22px;
}

.lista-anuncio-logo-utilizador{
    border-radius:50%;
    height: 70px;
    width: 70px;
}

.lista-anuncio-logo-utilizador-alt{
    border-radius:50%;
    height: 70px;
    width: 70px;
    color:rgb(175, 170, 170);
}


.lista-anuncio-titulo-vaga{
    font-size: 20px;
    color:#111;
    font-weight: 499;
}

.lista-anuncio-titulo-vaga-icon{
    color: #babd1b;
}

.lista-anuncio-data{
    color:rgba(0, 0, 0, 0.664);
    font-weight: 600;
    font-size: 14px;
}


.lista-anuncio-parte-distrito{
    color: rgba(17, 17, 17, 0.699);
}



.anuncio-part4-icon{
    color: rgb(143, 138, 138);
}



.all-anuncio{
    color: #222;
    border-bottom:1px solid #0d6efd !important;
    margin-top: 30px !important;
    transition: .5s !important;
    padding-bottom: 2px !important;
}

.all-anuncio:hover{
    color: #0d6efd;
}
