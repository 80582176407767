
*{
    box-sizing: border-box;
}

.perfil-container{
 
    display:flex;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
   
}

#perfil-formDados{
    margin-bottom: 100px !important;
}

.perfil-title{
    margin-top: 90px;
    margin-bottom: 30px;
}

.perfil-dados{
    margin-top: 20px;
}

.perfil-dados .cabecalhos{
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 3%;
}

.perfil-dados .cabecalhos .title-button{
    color: #150050;
    background-color: transparent;
    border: none ;
    padding: 10px;
    
}


.perfil-dados .cabecalhos .title-button:hover{
    background-color: #150050;
    color:white;
}

.activeTitleButton{
    background-color: #150050 !important;
    color:white !important;
    padding: 10px;
}

.activeTitleButton:hover{

    color:white;

}

.perfil-divisao{
    height: 1px;
    width: 95%;
    background-color: rgb(175, 170, 170);
    margin-left: 20px;
}



form{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
}

.perfil-foto-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.perfil-foto-button{
   
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

}



.perfil-foto-container .perfil-foto-button .input-foto #foto{

    display: none;

}


.perfil-foto-container .perfil-foto-button input, .perfil-foto-container .perfil-foto-button label{
    cursor: pointer;
    width: 90px;
    height: 37px;
    border: none;
    border-radius: 5px;
}

 .perfil-foto-container  .perfil-foto-view{

    height:180px;
    width: 180px;
    border: 2px solid rgb(175, 170, 170);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
   
}

.perfil-foto-container  .perfil-foto-view img{
    height: 180px;
    width: 180px;
    border-radius: 50%;
 
}

.user-img{
    height: 70%;
    width: 70%;
    color:rgb(175, 170, 170);
}

 .perfil-foto-container .perfil-foto-button label{
    color: rgb(17, 94, 117);
}




.perfil-foto-container .perfil-foto-button .btn-remover-foto{

    background-color: rgb(182, 26, 26);
    color: white;
 }

.perfil-foto-container .perfil-foto-button .btn-remover-foto:hover{

    background-color: rgb(199, 33, 33);
 }

 .perfil-foto-btn{
    width: 100px;
 }

 

 /*PERFIL DADOS*/

 .perfil-button{
    position: relative;
    left: 0;
    height: 50px;
    width: 30%;
    border: none;
    background-color: #150050 !important;
    color: white !important;
    border-radius: 5px;
    font-weight: 500;
    margin-left: auto;
    margin-right: 80px;
 }
 .perfil-button:hover{
    
    background-color: #1f0369 !important;
    color: white !important;
 }

 textarea{
    resize: none;
 }

 label{
    font-weight: 500;
    color: rgb(77, 75, 75);
 }

 .obg{
    font-size: 18px;
    color: red;
}

/*FORMA DADOS 2*/

.curr-input{
    display: none;
}

.col-curri label{
    cursor: pointer;
}

.btn-del-curi{
    background-color: rgb(182, 26, 26) !important;
    color: white !important;

}

.btn-del-curi:hover{
    background-color: rgb(199, 33, 33);
}

