
.vagas-candidatadas-container{

    height: 100vh;
    margin-top: 130px !important;

}

.btn_CandVaga{
    cursor: pointer;
    height: 20px;
    width: 20px;

}

.btn_CandVaga:last-child{
    margin-left: 15px;
}


.marginB{
    margin-bottom: 2px;
}