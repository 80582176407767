

.dash-footer{
    /*
    width: 82.5%;
    margin-left: 17.5%;
    */
    border-top: 1px solid rgb(203, 204, 206);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(228, 230, 231);
   
}
