
.vagas-criadas-container{

    height: 100vh;
    margin-top: 130px !important;

}

.btn_CriarVaga{
    cursor: pointer;
    height: 20px;
    width: 20px;

}

.btn_CriarVaga:last-child{
    margin-left: 15px;
}


.marginBCV{
    margin-bottom: 2px;
}

.anuncio-part1-empresa-info-zona, .anuncio-part1-empresa-info-data{
    color: #6c757d !important;
}