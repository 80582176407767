
.perquisar-perfil-empresa-container{
    min-height: 100vh;
    background: url(../../../../assets/anuncio-capa.jpg);
    padding-top: 115px;
}


.pesquisar-perfil-empresa-categoryBoby div span{
    margin-left: 2px;
}



.perquisar-perfil-empresa-body{
    background-color: #ffff;
    min-height: 115vh;
    padding-top: 90px;
    margin-top: 55px;
}

.pesquisar-perfil-empresa-icon{
    cursor: pointer;
    color: rgba(0, 0, 0, 0.486);
    transition: .7s ease-in-out;
    font-size: 20px;
}

.pesquisar-perfil-empresa-icon:hover{
    color: rgba(0, 0, 0, 0.829);
}


.pesquisarperfil-empresa-nome {
    color: black;
    font-size: 16px;
}

.pesquisarperfil-empresa-distrito{
    font-size: 16px;
}

.pesquisarperfil-empresa-foto {
    height:20px;
    width: 20px;
}

.pesquisarperfil-empresa-foto  img{
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

.pesquisarperfil-empresa-data{
    color: rgba(0, 0, 0, 0.801);
    font-size: 14px;
}

.pesquisarperfil-empresa-formacao{
    color: #dd2b0c !important;
}



/*Paginação*/

.page-link:focus{
    box-shadow: none;
}

