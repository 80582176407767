
#footer{
    background: linear-gradient(to right,#0c0717, #150050);
}



/*IMAGEM*/
#footer-img{
    top:0;
    width: 150px;
}

.footer-links{

   color: #6c757d;

}

.footer-links:hover{

    color:white; 
}

.footer-icon{
    height:30px;
    width:30px;
}

.footer-icon:hover{
    color: #c713af;
}

@media only screen and (max-width: 767px) {

    .footer-section p{
        text-align: center;
    }
   
  }
  

