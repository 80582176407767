* {
  scroll-behavior: smooth !important;
}

h1 {
  font-weight: 100;
}

h3,
h2 {

  color: rgba(0, 0, 0, .8) !important;
}

p{
  text-align: justify;
}

label {
  font-weight: 500;
  color: rgb(77, 75, 75);
}

.container-height {
  height: 100vh;
}


body,
html {
  transition: 0.5s !important;
  
}

a {
  text-decoration: none !important;
}

[data-title]{
  position: relative;
}

[data-title]:hover::after{
  opacity: 1;
  transition: all .1s ease .5s;
  visibility: visible;
}

[data-title]::after{
  content: attr(data-title);
  background-color: #1f0369;
  color: #fff;
  font-size: 14px;
  position: absolute;
  padding: 3px 20px;
  bottom: -1.6em;
  left: 100%;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111;
  z-index: 999999;
  visibility: hidden;
  border-radius: 6px;
}

.input-form-data{
  height: 45px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
select:focus,
input[type="number"]:focus,
textarea:focus{
  border-color: #1f0369 !important;
  box-shadow: none !important;
}
select{
  color: #666 !important;
}
input::placeholder {
  color: #888 !important;
}

.borderRadius-boerderTop {
  border-radius: 8px 8px 0px 0px;
  border-left: 2px solid #1f0369 !important;
}
input::placeholder{
  color: rgba(0, 0, 0, 0.616) !important;
}