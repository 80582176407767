
.home-title {
  font-size: 2.3rem;
  font-weight: 700;
  color:#fefefe;
  text-transform: uppercase;
}

.home-title span{
  color: #ffa807;
}

.home-paragrafo{
  color: #ced4da;
}
/*
.home-conteudo-subtitle{
  font-size: 1.6rem;
  color: #ffa807;
}

.home-conteudo-buscar{

  border: 5px solid rgb(192, 188, 188);
  border-radius: 50px 50px 50px 50px ;
}
.home-conteudo-buscar input[type="text"]{
  border-radius: 50px 0px 0px 50px;
  border-right: 1px solid rgb(240, 236, 236);
  padding: 20px;
  font-weight: 500;
  color: initial;

}

.home-conteudo-buscar select{
  padding: 20px;
  font-weight: 500;
  border-right: 1px solid rgb(240, 236, 236);
  border-left: 1px solid rgb(240, 236, 236);

}



 .home-conteudo-buscar button{
  font-weight: 500;
  border-radius: 0px 50px 50px 0px ;
  border-left: 1px solid rgb(240, 236, 236);
  background-color: #37198c;
  color: white;
}


.home-conteudo-buscar button:hover{
  background-color:#250a6f;
}



.buscar-input{
  outline: none;
  height: 70px;
  border: none;
}

/*Carroussel

.carousel-item{
  height: 100vh !important;
}

.carousel-caption{
 margin-bottom: 80px !important;
}

.carousel-caption p{
  color: #fefefe !important;
  background-color: rgba(17, 17, 17, 0.123) !important;
}
.carousel-title{
  font-size: 6em !important;
  color: #8860F7;
}
*/

.home-banner {
  min-height: 100vh !important;
  background: linear-gradient(#0c071796, #0c071796), url("../../assets/company.jpg");
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}


.home-inputs{
  height: 60px;
  border: none !important;
  outline: none !important;
}

.home-inputs:nth-child(2){
  border: 1px solid #ced4da !important;
}
.home-inputs:focus{
  border-right: transparent !important;
}

.home-inputs:nth-child(1){
  border-radius: 30px 0px 0px 30px ;
  padding-left: 15px;
}

.home-banner button{
  height: 60px;
  border: none !important;
  outline: none !important;
  border-radius: 0px 30px 30px 0px ;
  background-color: #600094ef;
}

.home-banner button:hover{
  background-color:#610094;
}


.banner-btn{
  height: 80px;
  margin-top: 150px;
  transition: ease all;
  animation: float 3s infinite ease-in-out;
  cursor: pointer;
  font-size: 50px;
}

.banner-btn-incon {
  color: #ffa807;
}


/*------------------------------*/

@media only screen and (max-width: 991px) {
  .home-title{
    font-size: 1.9rem;
    margin-top: 120px;
  }

  .banner-btn{
    margin-top: 0px;
    display: none !important;
  }
  .banner-btn .banner-btn-incon{
    display: none !important;
  }
  .home-inputs:nth-child(1), .home-inputs:nth-child(4){
    border-radius: 0px 0px 0px 0px ;
  }
 
}

@media only screen and (max-width: 460px) {


.home-banner {
min-height: 90vh !important;

}
}



/*----------------------------------------*/

@keyframes float {
  50% {
    padding-bottom: 20px;
  }

  100% {
    padding-bottom: 0px;
  }
}