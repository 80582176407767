.card-scroll{
    display: flex;
    align-items: center ;
    justify-content: center;
    opacity:1;
    width:40px;
    height:40px;
    background-color: #610094;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    position: fixed;
    top: 88%;
    right: 4%;
    font-size: 40px;
    font-weight: 700;
    border-color: transparent;
    transition: 0.5s;
}

/*.card-scroll::before{
    content: "^";
    
}
*/
.hidden{
    opacity:0;
    color:transparent;
}

.scroll-icon{
    width: 60%;
}


@media only screen and (max-width: 991px) {

    .card-scroll{
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }      

}