.actions-container{
   padding-right: 12px;
}

.actions-body{
    padding: 50px !important;
}
.actions-body ol{
    list-style-image: url("../../../assets/img/aceitar.png");
    list-style-position: outside;
}


.actions-body-1{
    box-sizing: border-box !important;
    background: linear-gradient(#38198cd0, #38198cd0), url("../../../assets/company.jpg");
    background-size: cover !important;
    background-position: center;
}

.actions-body-2{
    box-sizing: border-box !important;
    background: linear-gradient(#0c0717cb, #0c0717cb), url("../../../assets/profissional.jpg");
    background-size: cover !important;
    background-position: start;
}

.actions-btn{
    width: 150px !important;
    padding: 8px 0px !important;
    color: #fff !important;
    font-weight: 500 !important;
    transition: .5s ease all !important; 
    border: none !important;
    border-radius: 35px !important;
}

.actions-btn-1{
    background-color: #0c0717 !important;
}

.actions-btn-1:hover{
    background-color: #0a0614 !important;
    
}

.actions-btn-2{
    background-color: #37198c !important;
}
.actions-btn-2:hover{
    background-color: #2f147a !important;
    
}