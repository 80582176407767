.check-box-component{
    -webkit-appearance: none;
    visibility: hidden;
    display: none;
}

.check-box-span-component{

    position: relative;
    display: block;
    width: 22px;
    height: 12px;
    background: #adb5bd;
    cursor: pointer;
    border-radius: 20px;
    overflow: hidden;
    transition: ease-in 0.5s;

}


.check-box-component:checked ~ .check-box-span-component{

    background: #adb5bd;
    
}

.check-box-span-component::before{
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    background: #dc3545;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transition: .5s;
   
}


.check-box-component:checked ~ .check-box-span-component::before{

    transform: translateX(-50px);
}


.check-box-span-component::after{
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    background: #198754;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transition: .5s;
    transform: translateX(50px);
}

.check-box-component:checked ~ .check-box-span-component:after{

    transform: translateX(0px);
}