
.dash-nav{
    background-color: #fff;
    height: 65px; 
}

.user-icon{
    width: 30px;
    height: 30px;
}

.dash-nav-logo{
    top:0;
    width: 220px;
}

#dropdown-nav-dash{
    position:absolute;
    right: 40px;
    bottom: 10px;
    background-color: #fff;
}

#dropdown-menu-nav-dash{
    position: absolute;
    top: 55px;
    right: 0px;
    background-color: #fff !important;
}

.dropdown-menu-nav-dash-item:hover{

    background-color: #f2f5f7 !important;
}
/*
#dropdown-nav-dash:hover>#dropdown-menu-nav-dash {
    display: none;
  }
  
  #dropdown-nav-dash>.dropdown-toggle-nav-dash:active {
    /*Without this, clicking will make it sticky
      pointer-events: block;
      display: block;
  }
  */

