

.container-height{

    min-height: 100vh !important;
}

.padding{
    padding-right: 25px;
    padding-left: 15px;
    padding-bottom: 20px;
    padding-top: 10px;
}
.margin-top{
    margin-top: 100px;
}  


#login-image img{
    height: 100%;
    width: 100%  
}

#login-image{
    padding: 0%;
}



.sessao-text{
    color: rgb(76, 71, 71);
}

.input-login{
    height: 50px;
    font-size: 14px;
}


.input-label{
    margin-left: 10px;
}

#button{
    height: 45px;
    font-weight: 500;
}

.autenticar-facebook-button{
    height: 45px;
    width: 100%;
    background-color:#4267B2;
    color: white;
    font-size: 14px;
}

.autenticar-facebook-button:hover{
    color: white;
    background-color:#2b53a3;
}

.autenticar-google-button{
    height: 45px;
    width: 100%;
    background-color: #db4a39;
    color: white;
    font-size: 14px;
}
.autenticar-google-button:hover{
    color: white;
    background-color: #c83b2b;
}

#link-bottom{
    color: #4772c8;
    transition: 0.5s;
}
#link-bottom:hover{
    font-size: 16px;
}


@media only screen and (max-width: 991px) {

    .login-body{

        width: 450px !important;
        padding-left: 25px !important;
    }

    .login-page-form{
        margin-top: 30px !important;
    }
    
   
  }

  @media only screen and (max-width: 493px) {
 
    .login-body{

        width: 350px !important;
    }
    
   
  }

    @media only screen and (max-width: 400px) {
 
    .login-body{

        width: 340px !important;
    }
    
   
  }

    @media only screen and (max-width: 385px) {
 
    .login-body{

        width: 320px !important;
    }
    
   
  }
  

   @media only screen and (max-width: 360px) {
 
    .login-body{

        width: 300px !important;
    }
    
   
  }

    @media only screen and (max-width: 325px) {
 
    .login-body{

        width: 285px !important;
    }
    
   
  }
  
  