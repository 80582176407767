
.card-container .card-box{
    background: rgba(155, 255, 255, .1);
    margin: 15px;
    box-shadow: 0 5px 10px rgba(0,0,0,.3);
    border-radius: 5px;
    width: 300px;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 0;
    transition: .2s linear;
    backdrop-filter: blur(5px);
}

.card-container .card-box::before{
    content:'';
    position: absolute;
    top: -35%;
    left: 50%;
    transform: translateX(-50%);
    height: 300px;
    width: 300px;
    background: rgba(255,255,255,.2);
    border-radius: 50%;
    z-index: -1;
}

.card-container .card-box:hover{
    transform: scale(1.02);

}

.card-container .card-box .card-title{
    color: #fff;
    font-size: 20px;
    padding: 5px 0;
    padding-top: 30px;
    text-transform: uppercase;
    font-weight: 700;
}

.card-container .card-box .card-price{
    color: #fff;
    font-size: 45px;
    padding: 5px 0;
    padding-bottom: 25px;
}

.card-container .card-box .card-price span{
    font-size: 20px;
    padding: 0 2px;
}

.card-container .card-box ul{
    padding: 10px 30px 0;
    list-style: none;
}


.card-container .card-box ul li{
    font-size: 16px;
    color: #eee;
    padding: 7px 0;
}

.card-container .card-box a{
    display: block;
    padding: 15px 0;
    background: rgba(255, 255, 255,.2);
    color: #fff;
    font-size: 20px;
    text-decoration: none;
}

.card-container .card-box a:hover{
    background: rgba(255, 255, 255,.4);
}

@media (max-width:768px){
    .card-container::before{
        clip-path: circle(20% at 10% 35%);
    }
}