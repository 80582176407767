:root {
  --main-bg-color: #fff;
  --main-text-color: #fff;
  --second-text-color: #bbbec5;
  --second-bg-color: #c1efde;
  --bg-dash-card: #6777ef;
}

/*COLOR*/

.color-dark{
  color: #44454c;
}
/*FIM DE COLOR*/

.box-sizing{
  box-sizing: border-box;
}

/*Data-table*/

.data-table-extensions{
  margin-bottom: 12px;
  width: 95% !important;
  max-width: 95% !important;
  margin-left: 2.5%;
}


.eSTlnH{
  width: 90% !important;
  max-width: 90% !important;
  margin-left: 5%;
}

.jxflYm{

  width: 90% !important;
  max-width: 90% !important;
  margin-left: 5%;

}

.gZWIKX{

  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: #111 !important;
}

/*Data-table Fim*/

/*Ícone*/

.icone-color{

  color: #858796;
}

.rounded-full {
  border-radius: 100%;
}

#wrapper {
  margin-top: 65px;
  overflow-x: hidden;
  background-color: #f8f9fc;

}


#page-content-wrapper {
  min-width: 100vw;
  margin-top: 40px;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

#menu-toggle {
  cursor: pointer;
}

.list-group-item {
  border: none;
  padding: 20px 30px;
}

.list-group-item.active {
  background-color: transparent;
  color: var(--main-text-color);
  font-weight: bold;
  border: none;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
