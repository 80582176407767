
.category-container{
    min-height: 70vh;
    margin-bottom: 30px;
}

.category_container_body{
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}
.popularCategory-title{
    text-transform: uppercase;
    font-weight: 700;
    color: rgba(0, 0, 0, .8) !important;
    font-size: 2em !important;
    margin-top: 50px;
}