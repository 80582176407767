
*{
    box-sizing: border-box;
}



.empresa-perfil-container{
    max-height: 270vh;
    display:flex;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 200px !important;
    
}

.empresa-perfil-title{
    margin-top: 80px;
}

.empresa-perfil-dados{
    margin-top: 20px;
    border-radius: 10px;
}

.empresa-perfil-dados .cabecalhos{
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 3%;
}

.empresa-perfil-dados .cabecalhos .empresa-title-button{
    color: white;
    background-color: #150050;
    border: none ;
    padding: 10px;
    
}


.empresa-perfil-dados .cabecalhos .empresa-title-button:hover{
    background-color: #29106d;
   
}

.title-divisor{
    color: #150050;
   
}

.activeTitleButton:hover{

    color:white;

}

.empresa-perfil-divisao{
    height: 1px;
    width: 95%;
    background-color: rgb(175, 170, 170);
    margin-left: 20px;
}



form{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
}

.empresa-perfil-foto-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.empresa-perfil-foto-button{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

}



form .empresa-perfil-foto-container .empresa-perfil-foto-button .input-foto #foto{

    display: none;

}


form .empresa-perfil-foto-container .empresa-perfil-foto-button input, form .empresa-perfil-foto-container .empresa-perfil-foto-button label{
    cursor: pointer;
    width: 90px;
    height: 37px;
    border: none;
    border-radius: 5px;
}

form .empresa-perfil-foto-container  .empresa-perfil-foto-view{
    height:200px;
    width: 200px;
    border: 2px solid rgb(175, 170, 170);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

form .empresa-perfil-foto-container  .empresa-perfil-foto-view img{
    height: 200px;
    width: 200px;
    border-radius: 50%;
 
}

form .empresa-perfil-foto-container .empresa-perfil-foto-button label{
    color: #4589ee;
}


form .empresa-perfil-foto-container .empresa-perfil-foto-button input[type="button"]{

    background-color: rgb(182, 26, 26);
    color: white;
    margin-left: 5%;
 }

 form .empresa-perfil-foto-container .empresa-perfil-foto-button input[type="button"]:hover{

    background-color: rgb(199, 33, 33);
 }



 /*PERFIL DADOS*/

 .empresa-perfil-button{

    height: 50px;
    width: 50%;
    border: none;
    background-color: #150050;
    color: white;
    border-radius: 5px;
    font-weight: 500;
 }
 .empresa-perfil-button:hover{
    
    background-color: #1f0369;
 }

 textarea{
    resize: none;
 }

 label{
    font-weight: 500;
    color: rgb(77, 75, 75);
 }

 .obg{
    font-size: 18px;
    color: red;
}


