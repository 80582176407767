
.toast{
    z-index: 10000 !important;
}
#regist-image img{
    width: 100%;
    height: 100%;
}

#regist-image{
    padding: 0%;
    background-size: cover !important;
    box-sizing: border-box !important;
}


.sessao-text{
    color: rgb(76, 71, 71);
}

.input-regist{
    height: 50px;
    padding-left: 15px;
    font-size: 14px;
}



.input-label-regist{
    margin-left: 10px;
}

#button-regist{
    height: 45px;
    font-weight: 500;
}


#link-bottom-regist{
    color: #4772c8;
    transition: 0.5s;
}
#link-bottom-regist:hover{
    font-size: 16px;
}