.btn-t{
  margin: auto !important;
}
.texto_inicio{
    text-align: start !important;
}
.listar-nome {
    color: black;
    font-size: 18px;
    text-align: start !important;
}

.listar-distrito{
    font-size: 14px;
    text-align: center;
}

.listar-foto {
    display: flex;
    align-items: center;
    justify-content: center;
}

.listar-foto  img{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin-left: 0px !important;
}

.listar-foto .listar-foto-icon{
    width: 45px !important;
    height: 45px !important;
    border-radius: 100%;
    margin-left: 0px !important;
    color: rgb(179, 170, 170);
}


.listar-data{
    color: rgba(0, 0, 0, 0.801);
    font-size: 12px;
}

.listar-formacao{
    color: #dd2b0c !important;
   font-size: 18px; 
}




/*Paginação*/

.page-link:focus{
    box-shadow: none;
}

