
.notfound{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

h1{
    color:#0c0717;
}

.emoj{
    font-size: 150px;
}


@media only screen and (max-width: 850px) {

    .emoj{
        font-size: 130px;
    }
}

@media only screen and (max-width: 450px) {

    .emoj{
        font-size: 100px;
    }
    h1{
        font-size: 20px;
    }
}



