
*{
    box-sizing: border-box;
}

.anuncio-container{
    min-height: 100vh;
    display:flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 100px !important;  
}


.anuncio-title{
    margin-top: 70px;
    margin-bottom: 30px;
}

.anuncio-dados-container{
    margin-top: 20px;
}

.anuncio-dados-body{
    margin-top: 20px;
    border-radius: 10px;
}



form{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
}

.anuncio-foto-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.nota-color{

    color: red;
}

.obg{
    font-size: 18px;
    color: red;
}




 /*Anúcio DADOS*/

 .anuncio-button{
    height: 50px;
    width: 30%;
    border: none;
    background-color: #150050 !important;
    color: white !important;
    border-radius: 5px;
    font-weight: 500;
    margin-left: auto;
    margin-right: 75px;
 }

 .anuncio-button:hover{
    background-color: #1f0369 !important;
    color: white !important;
 }
 

