

.card_container{
    height: 160px;
    border-radius: 10px;
}

.pesquisarPorCategoria{
    color: #222;
    border-bottom:1px solid #0d6efd !important;
    margin-top: 30px !important;
    transition: .5s !important;
    padding-bottom: 2px !important;
}

.pesquisarPorCategoria:hover{
    color: #0d6efd;
}



.a{
    background-color: #6610f2;
}
.e{
    background-color: #fd7e14;
}

.t{
    background-color: #0d6efd;
}

.p{
    background-color: #d63384;
}

.m{
    background-color:#dc3545; 
}

.g{
    background-color: #ffc107;
}

.c{
    background-color: #198754;
}
.d{
    background-color: #20c997;
}

.i{
    background-color: #0dcaf0;
}