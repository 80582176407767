

.listar-nomeuser-vaga {
    color: black;
    font-size: 18px;
}

.listar-distrito-vaga{
    font-size: 14px;
    text-align: center;
}

.listar-foto-uservaga{
    display: flex;
    align-items: center;
    justify-content: center;
}

.listar-foto-uservaga  img{
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

.listar-data-vaga{
    color: rgba(0, 0, 0, 0.801);
    font-size: 12px;
}

.listar-titulo-vaga{
    color: #dd2b0c !important;
   font-size: 18px; 
}



/*Paginação*/

.page-link:focus{
    box-shadow: none;
}

