
.container-visualizar-user{
    min-height: 100vh;
    background: url(../../../../assets/anuncio-capa.jpg) no-repeat fixed;
    padding-top: 115px;
}

.visualizar-user-details{
    background: #fff;
    margin-top: 90px;
}

.visualizar-user-details-imagem{
    position: absolute !important;
    width: 160px;
    height: 160px;
    padding: 15px;
    border-radius: 50%;
    border: 2px solid #fff;
    margin-left:44%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.visualizar-user-details-imagem img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    
}

.visualizar-user-details-imgAlt{

    width: 70%;
    height: 70%;
    color: rgb(179, 170, 170);
}

.visualizar-user-details-info{

    margin-top: 50px;
}

.visualizar-user-details-info-name{

    font-size: 1.5rem;
}

.visualizar-user-details-info-formacao span{

    color: #610094;
}

/*==================================================================*/

.visualizar-user-details-body{
    margin-top: 80px !important;
}



.visualizar-user-details-body-buttons button{
    width: 90%;
    border-radius: none;
    font-size: 1.1rem;
}


.visualizar-user-details-body-more{
    padding: 20px !important;
}

.visualizar-user-details-body-title {
   
    font-size: 1.3rem;
}

.visualizar-user-details-body-title-2{

    font-weight: 700;
    color: rgba(0,0,0,.8);
}

.visualizar-user-details-body-title-2 span{

    font-weight: 400;
}