

.admin-ed-user-name{

    font-size: 1.2em;
}


  #adminEditar-perfil{
    margin-bottom: 80px !important;
  }