
.tipo-vaga-1{
    border-radius: 5px;
    font-weight: 500;
    color: #0d6efd;
    border: 2px solid #0d6efd;
    background-color: transparent;
    cursor: pointer;
    width: 85%;
    padding: 6px 0px;
}

.tipo-vaga-2{
    border-radius: 5px;
    font-weight: 500;
    color: #37198c;
    border: 2px solid #37198c;
    background-color: transparent;
    cursor: pointer;
    width: 85%;
    padding: 4px;
}
.tipo-vaga-3{
    border-radius: 5px;
    font-weight: 500;
    color: #198754;
    border: 2px solid #198754;
    background-color: transparent;
    cursor: pointer;
    width: 85%;
    padding: 4px;
}

.tipo-vaga-4{
    border-radius: 5px;
    font-weight: 500;
    color: #dc3545;
    border: 2px solid #dc3545;
    background-color: transparent;
    cursor: pointer;
    width: 85%;
    padding: 4px;
}


.tipo-vaga-5{
    border-radius: 5px;
    font-weight: 500;
    color: #ffc107 ;
    border: 2px solid #ffc107 ;
    background-color: transparent;
    cursor: pointer;
    width: 85%;
    padding: 4px;
}


.heigh_big{
    height: 35px;
}

.heigh_small{
    height: 30px;
}


.tipo-vaga-2:hover{
    color: white;
    background-color: #37198c;
}

.tipo-vaga-1:hover{
    color: white;
    background-color: #0d6efd;
}
.tipo-vaga-3:hover{
    color: white;
    background-color: #198754;
}

.tipo-vaga-4:hover{
    color: white ;
    background-color: #dc3545 ;
}

.tipo-vaga-5:hover{
    color: white ;
    background-color: #ffc107 ;
}


