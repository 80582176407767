
.vagas-favoritas-container{

    height: 100vh;
    margin-top: 130px !important;

}

.btn_FavVaga{
    cursor: pointer;
    height: 20px;
    width: 20px;

}

.btn_FavVaga:last-child{
    margin-left: 15px;
}


.marginBFV{
    margin-bottom: 2px;
}