
.contacto-container{
    min-height: 145vh;
    background: url(../../../assets/anuncio-capa.jpg);
    padding-top: 110px;
}


.contacto-body{
    background-color: #ffff;
    min-height: 115vh;
    padding-top: 5px;
}

.contacto-form-title{

    position: relative;
    margin-right: auto;
}
/*===============================================*/


.contacto-equipa-foto{

    width: 110px;
    height: 110px;
    border-radius: 100%;
    border: 2px solid #370950;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contacto-equipa-foto img{
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

/*===============================================*/

.contacto-btn{
    
    height: 45px;
    width: 100%;
    border: none;
    background-color: #150050 !important;
    color: white !important;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
   
}

.contacto-btn-div{
    position: relative !important;
    left: 0 !important;
    margin-left: auto;
}

.contacto-btn:hover{
    
    background-color: #1f0369 !important;
    color: white !important;
 }


 .contacto-pais{
     color: rgba(0, 0, 0, 0.747);
 }

 /*======================*/

 .contacto-comunication-list li{
    list-style: none;
 }
 
.contacto-comunication-btn{
    outline: none !important;
    border: none !important;
    transition: .5s ease all;
    padding: 10px 40px 10px 40px;
}

.contacto-comunication-btn1{
    background-color: #1f0369 !important;
    color: white !important;
    margin-right: 10px;
}

.contacto-comunication-btn2{
    border: 1px solid #0c0717 !important;
    color: #0c0717 !important;
}


.contacto-comunication-btn1:hover{
    background-color: #150050 !important;
    color: white !important;
}

.contacto-comunication-btn2:hover{
    background-color: #0c0717 !important;
    color: #fff!important;
}