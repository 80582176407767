
.margin-top{
    margin-top: 100px;
}   


#regist-image-empresa img{
    height: 100%;
    width: 100%  
}

#regist-image-empresa{
    padding: 0%;
}



.sessao-text{
    color: rgb(76, 71, 71);
}

.input-regist-empresa{
    height: 50px;
    padding-left: 15px;
    font-size: 14px;
}



.input-label-regist-empresa{
    margin-left: 10px;
}

#button-regist-empresa{
    height: 45px;
    font-weight: 500;
}

.autenticar-facebook-button{
    height: 45px;
    width: 100%;
    background-color:#4267B2;
    color: white;
    font-size: 14px;
}

.autenticar-facebook-button:hover{
    color: white;
    background-color:#2b53a3;
}

.autenticar-google-button{
    height: 45px;
    width: 100%;
    background-color: #db4a39;
    color: white;
    font-size: 14px;
}
.autenticar-google-button:hover{
    color: white;
    background-color: #c83b2b;
}

#link-bottom-regist-empresa{
    color: #4772c8;
    transition: 0.5s;
}
#link-bottom-regist-empresa:hover{
    font-size: 16px;
}