
.container-tarifario{
    min-height: 100vh !important;
    /*background: #444;*/
    background: #0c0717;
    position: relative;
    margin-bottom: 100px;
    box-sizing: border-box !important;
}

.tarifario-title{
    margin-top: 70px !important;
}


.cards-list{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
}

.container-tarifario::before,
.container-tarifario::after{
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    z-index: -1;
}

/*
.container-tarifario::before{
    clip-path: circle(30% at 10% 10%);
    background: linear-gradient(#9a6aff, #610094);
}

.container-tarifario::after{
    clip-path: circle(20% at 90% 90%);
    background: linear-gradient(rgba(255,0,255,1), rgba(0,189,255,1));
}
*/

/* tarifario-title */

@media only screen and (max-width: 991px) {
    .tarifario-title{
        margin-top: 70px !important;
    }
}