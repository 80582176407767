:root {
  --main-bg-color: #fff;
  --main-text-color: #fff;
  --second-text-color: #bbbec5;
  --second-bg-color: #c1efde;
  --bg-dash-card: #6777ef;
}


#sidebar-wrapper.dropdown:hover>#sidebar-wrapper.dropdown-menu {
  display: none;
}

#sidebar-wrapper.dropdown>#sidebar-wrapper.dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
    pointer-events: block;
}

/*COLOR*/
.color-dark{
  color: #44454c;
}
.color-white{
  color: #fff;
}

/*FIM DE COLOR*/

/*ICONS*/

.dash-icon{
  height: 24px;
  width: 24px;
  color: #6777ef;
}

.dash-item-icon{
  color: #6777ef;
}


.box-sizing{
  box-sizing: border-box;
}


/*Ícone*/

.icone-color{

  color: #858796;
}

.rounded-full {
  border-radius: 100%;
}

.dash-sidebar{
  background-color: #0c0717 !important;
}

.sidebar-heading{

  color:#6c757d !important;

}
.sidebar-item{
  color:#fff!important;
  background: transparent !important;
  width: 100%;
  height: 70px;
  display: flex !important;
  align-items: center !important;
  font-size: 1.08em;
  border-top: 1px solid #111 !important;
  border-bottom: 1px solid #111 !important;
  font-weight: lighter !important;
  font-weight: 450 !important; 
  transition: .2s ease-in-out all !important;
}



.sidebar-item:hover{
  /*color: #fff !important;
  */
  border-left: 5px solid #6777ef !important;
}


.dash-icon-dashboard{
  width: 90%;
  background: transparent !important;
  border-radius: 5px;
  color:#fff !important;
  height: 45px;
  font-size: 1.1em;

}

#sidebar-wrapper {
  min-height: 90vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  background-color: #fff;

}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

/*Menu*/

.menu-color{
    background-color: #fff;
}

.menu-color:hover{
  background-color: rgb(169, 165, 165);
  color:#c713af;
}

.menu-color:active{
  background-color: rgb(169, 165, 165);
  color:#c713af;
}



@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
