:root {
  --main-bg-color: #fff;
  --main-text-color: #fff;
  --second-text-color: #bbbec5;
  --second-bg-color: #c1efde;
  --bg-dash-card: #6777ef;
}

/*COLOR*/

.color-dark{
  color: #44454c;
}
/*FIM DE COLOR*/

.box-sizing{
  box-sizing: border-box;
}

.card-color-text{
  color: #6c757d ;
 
}

#border-utilizadores{

  border-top: 3px solid #6777ef;
}

#border-company{
  border-top: 3px solid rgb(32, 163, 52);
  
}



#border-vagas{
  border-top: 3px solid rgb(240, 158, 91);
}

#border-individuais{
  border-left: 3px solid #ffc107;
}

.primary-text {
  color: var(--main-text-color);
}

.card-bg{
  background-color: var(--bg-dash-card);
}

.second-text {
  color: var(--second-text-color);
}

.primary-bg {
  background-color: var(--main-bg-color);
}

.secondary-bg {
  background-color: var(--second-bg-color);
}

/*Ícone*/
.icone-utilizadores-color{
  color: #6777ef;
}
.icone-company-color{

  color: rgb(32, 163, 52);
}
.icone-vagas-color{

  color: rgb(240, 158, 91);
}
.icone-individuais-color{

  color: #ffc107;
}
.icone-vagas-desativadas-color{
   color: rgb(218, 65, 65);
}



.rounded-full {
  border-radius: 100%;
}

#wrapper {
  overflow-x: hidden;
  background-color: #f8f9fc;

}

#page-content-wrapper-dashprincipal{
  margin-top: 30px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

#menu-toggle {
  cursor: pointer;
}

.list-group-item {
  border: none;
  padding: 20px 30px;
}

.list-group-item.active {
  background-color: transparent;
  color: var(--main-text-color);
  font-weight: bold;
  border: none;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
