span.loading{
    width: 60px;
    height: 60px;
    display: block;
    border-radius: 100%;
    border: 5px solid purple;
    border-right: 5px solid #ced4da;
    border-bottom: 5px solid #ced4da;
    animation: loading 1s linear infinite;
    background: transparent !important;
    margin: 20px 0px 25px 0px;
}


@keyframes loading {
    0%{

        transform: rotate(0deg);

    }

    100%{

        transform: rotate(360deg);

    }
}