.nav-home{  
/*border-bottom: 2px solid rgb(212, 58, 164);*/
position: static;
height:80px; 
padding: 0px 4% 0px 4%  !important;
transition: .5s !important;
background:linear-gradient(to right,#0c0717, #150050) !important;
display: flex;
align-items: center;
justify-content: space-around;
}

.admin-icon{
    margin-left: -2px;
}

.nav-home-home{  
    /*border-bottom: 2px solid rgb(212, 58, 164);*/
    position: static;
    height:80px; 
    padding: 0px 4% 0px 4% !important;
    transition: .5s !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
    }



#scroll-hight{
   /* height: 90px;*/
    background:linear-gradient(to right,#0c0717, #150050) !important;

}


.dropdown-menu{
    background-color: #250a6f !important;
}
.dropdown-item{
    cursor: pointer;
    transition: .5s ease all !important;
}
.dropdown-item:hover{
    
    background-color:#37198c !important;
    
}
/*
.login-link{
    background-color: #600094ef;
    border-radius: 10px;
    transition: .5s ease all;
}

.login-link-alt{
    background-color: transparent;
}
.login-link:hover{
    background-color: #610094;
}
*/


/* Margem do ícone direito do menu*/

.margin-right{
    right: 40px;
}

.right{
    right: .1px;
}

.icon-marginright{
    margin-right: 5px !important;
}

.item-menu-1{
    font-size: 16px;
    font-weight:400;  
}

.margin-right2{
    margin-right: 40px !important;
    
}



.dropdown:hover>.dropdown-menu {
    display: block;
  }
  
  .dropdown>.dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
      pointer-events: none;
  }
  
/*IMAGEM*/
#logo{
    margin-right: 50px;
}
#logo img{
    width: 200px;
}

.opc ul{
        display: flex;
        margin-right: 300px;
        color: black;   
}
    

.opc ul li{
        list-style: none;
        margin-left: 20px;
        
}

.opc ul li a{
            text-decoration: none;
           
}
.opc ul li a:hover{
                color: rgb(212, 53, 224);;
            }


/*=======================*/


/*=======================*/ 
@media only screen and (max-width: 1024px){
    .nav-home{
        padding: 0px 2% 0px 3%  !important;
    }
}  
@media only screen and (max-width: 991px) {

    .nav-home{
        display: flex;
        justify-content: center;
    }

    .nav-home-home{
        background:linear-gradient(to right,#0c0717, #150050) !important;
    }

    .navbar-collapse{
        background:linear-gradient(to right,#0c0717, #150050) !important;
        align-items: center;
        position: absolute;
        top: 70px;
        width: 100%;
        height: 100vh;
        padding: 100px 0px 0px 0px;
        left: 0px;

    }
    
    .navbar-nav{
        background:linear-gradient(to right,#0c0717, #150050) !important; 

    }

    .item-menu-1,.item-menu-2 {
        margin-left: 50px;
        padding-left: 100px !important;

    }

    .item-menu-1:hover{
        background-color:transparent !important;
        
    }

    .dropdown-item{
        cursor: pointer;
        transition: .5s ease all !important;
    }
    .dropdown-item:hover{
        
        background-color:transparent !important;
        
    }

    
    .dropdown-menu{
       /* background-color: transparent !important;*/
       width: 50%;
       margin-left: 80px !important;
       text-align: center;
    }
    

    .nav-home .dropdown-menu{
        width: 50%;
        margin-left: 50px;
    }

    #scroll-hight{
        height: 75px;
    }
    
    #logo img{
        width: 200px;
    }
    
    .registo_login{
        flex-direction: column !important;
    }

    .login-link{
        background-color: transparent;
    }
}


@media only screen and (max-width: 450px) {
  

    #logo img{
        width: 150px !important;
    }
    


}