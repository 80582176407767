.contador-container{
    box-sizing: border-box !important;
   
}

.contador{
  
   /* background: linear-gradient(to right,#0c0717, #150050);*/
   background:#0c0717;
    min-height: 30vh;
   
}



.contador-quantidade-Vagas, .contador-quantidade-membros, .contador-quantidade-individual {

   border-right:.5px solid rgba(136, 131, 131, 0.473);
   color: #fff;
}



@media screen and (max-width: 991px){

    .contador-quantidade-Vagas, .contador-quantidade-membros{

        margin: 50px 0px 40px 0px !important;
       
     }

     .contador-quantidade-individual, .contador-quantidade-empresas{

        margin: 0px 0px 50px 0px;
     }
    
  }


  
@media screen and (max-width: 575px){
     .contador-quantidade-membros{

        margin: 0px 0px 30px 0px !important; 
     }

     .contador-quantidade-individual{

        margin: 0px 0px 30px 0px;
     }
    
  }
  
  